// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    console.log("connected");
  }

  static values = {
    url: '/employee_emails/' // The URL for the DELETE request
  };

  delete(event) {
    event.preventDefault(); // Prevent default link behavior
    const emailCheckboxes = document.querySelectorAll('.email-message');

    // Iterate over each checkbox
    emailCheckboxes.forEach(checkbox => {
      if (checkbox.checked) {
        // Extract the email ID from the data-email-id attribute
        const emailId = checkbox.dataset.emailId;

        fetch(this.urlValue + `${emailId}`, {
          method: "DELETE",
          headers: {
            "X-CSRF-Token": this.getCsrfToken(),
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        })
          .then(response => {
            if (response.ok) {
              const checkbox = document.querySelector(`.email-message[data-email-id="${emailId}"]`);

              if (checkbox) {
                // Navigate to the closest parent <tr> element
                checkbox.closest('tr').remove();
              }
            } else {
              return response.json().then(data => {
                alert(data.error || "Failed to delete the item");
              });
            }
          })
          .catch(error => {
            console.error("Error:", error);
            alert("An error occurred. Please try again.");
          });
      }
    })
    alert("Messages deleted")
  }

  checkAll(event) {
    const contestId = event.currentTarget.dataset.contestId;
    console.log(contestId)
    console.log(`.email-message[data-contest-id="${contestId}"]`)
    const emailCheckboxes = document.querySelectorAll(`.email-message[data-contest-id="${contestId}"]`);

    if (emailCheckboxes) {
      emailCheckboxes.forEach(checkbox => {
        checkbox.checked = true;
      })
    }
  }

  getCsrfToken() {
    const meta = document.querySelector('meta[name="csrf-token"]');
    return meta && meta.content;
  }
}
