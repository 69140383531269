// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select"]; // Targets for select box and form elements

  connect() {
    console.log("SelectController connected");
  }

  async fetchData(event) {
    const selectedValue = event.target.value;
    const url = `/inventories/${selectedValue}`;
    const id = event.srcElement.dataset.id ?? "";
    const name = document.querySelector(`#name_${id}`);
    const description = document.querySelector(`#description_${id}`);
    const size = document.querySelector(`#size_${id}`);
    const amount = document.querySelector(`#amount_${id}`);

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }
      const data = await response.json();

      if (name) name.innerHTML = data.name;
      if (description) description.value = data.description;
      if (size) size.innerHTML = data.size_category.name;
      if (amount) amount.value = data.price;
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }

  }

  updateForm(data) {
    // Example of how to update the form fields
    // Customize this according to your form structure
    Object.keys(data).forEach((key) => {
      const field = this.element.querySelector(`[name="${key}"]`);
      if (field) {
        field.value = data[key];
      }
    });
  }
}
